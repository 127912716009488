import { FC } from 'react';
import FormBuilderBase from '../../components/form-builder/FormBuilderBase';
import { PlatformIcons } from '../../components/shared/icon/IconRender';
import { v4 as uuid } from 'uuid';
import { useRecoilValue } from 'recoil';
import { currentClientAtom, currentTenantIdAtom } from '../../recoil/atoms/Clients';
import { useTranslation } from 'react-i18next';
import { AdminLayout } from './Layout';

const FormBuilder: FC = () => {
  const currentClient = useRecoilValue(currentClientAtom);
  const currentTenantId = useRecoilValue(currentTenantIdAtom);
  const { t } = useTranslation('form-builder');

  return (
    <AdminLayout>
      <FormBuilderBase
        initialForm={{
          id: uuid(),
          code: '',
          version: 1,
          iconCode: PlatformIcons.FORM,
          translations: {},
          sections: [],
          placeholders: [],
        }}
        topNavHeadingKey="form-builder:heading.form-builder"
        failsafeKey="form"
        breadCrumbs={[
          {
            name: t('breadcrumb.form-builder'),
            path: currentClient?.id ? `/clients/${currentClient.id}/organisation#forms` : `/builder/${currentTenantId}#form-builder`,
          },
        ]}
      />
    </AdminLayout>
  );
};

export default FormBuilder;
